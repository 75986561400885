import { z } from 'zod';
import {
  ResourceIdSchema,
  SongIdSchema,
  UserId,
  WorkspaceIdSchema,
} from '../common/Opaques';
import { Song } from '../common/Song';
import { transformToNull } from '../common/zod-utils';

export const SharedPlaylistSchema = z.object({
  // cover_image_orig_path: z.string().nullish().transform(transformToNull),
  cover_image_path: z.string().nullish().transform(transformToNull),
  // created_at: z.string(),
  // deleted_at: z.nullable(z.string()),
  description: z.string().nullish().transform(transformToNull),
  // is_unread: z.boolean(),
  playlist_id: z.string(),
  // playlist_read_at: z.string(),
  // project_id: z.string().nullish().transform(transformToNull),
  // resource_id: ResourceIdSchema,
  song_ids: z.array(z.string()),
  title: z.string(),
});

export type SharedPlaylist = z.infer<typeof SharedPlaylistSchema>;

export const AudioSchema = z.object({
  // added_to_playlist_at: z.string(),
  audio_id: SongIdSchema,
  // cover_image_orig_path: z.string().nullish().transform(transformToNull),
  cover_image_path: z.string().nullish().transform(transformToNull),
  // created_at: z.string(),
  // deleted_at: z.nullable(z.string()),
  duration: z.number(),
  // metadata_album: z.nullable(z.string()),
  // metadata_album_artist: z.nullable(z.string()),
  metadata_artist: z.nullable(z.string()),
  // metadata_bitrate: z.nullable(z.number()),
  // metadata_bpm: z.nullable(z.number()),
  // metadata_channels: z.nullable(z.number()),
  // metadata_codec_profile: z.string().nullish(),
  // metadata_composer: z.string(),
  // metadata_copyright: z.nullable(z.string()),
  // metadata_format: z.string(),
  // metadata_genre: z.string(),
  // metadata_rating: z.nullable(z.number()),
  // metadata_sample_rate: z.number(),
  // metadata_title: z.nullable(z.string()),
  // metadata_track_count: z.nullable(z.number()),
  // metadata_track_number: z.nullable(z.number()),
  // metadata_year: z.nullable(z.number()),
  // original_filename: z.nullable(z.string()),
  // path: z.string(),
  // size: z.number(),
  title: z.string(),
  // transcoding: z.number(),
  // uploaded_at: z.string(),
  // version_id: z.number(),
});

export type Audio = z.infer<typeof AudioSchema>;

// export const SharedLinkErrorSchema = z.object({
//   error: z.literal('PASSWORD_REQUIRED'),
// });

// export type SharedLinkError = z.infer<typeof SharedLinkErrorSchema>;

export const SharedLinkSchema = z.object({
  // api_root_url: z.string(),
  // artist_list: z.array(z.string()),
  // artist_list_stringified: z.string(),
  audio_list: z.array(AudioSchema),
  cover_image_path: z.string().nullish().transform(transformToNull),
  disabled_download: z.number(),
  password: z.string(),
  player_type: z.string(),
  playlist: z.nullable(SharedPlaylistSchema),
  scope: z.string(),
  // total_duration: z.number(),
  // total_duration_min: z.number(),
  workspace: z.object({
    image_url: z.string().nullish(),
    string_id: z.string(),
    title: z.string(),
  }),
});

export type SharedLink = z.infer<typeof SharedLinkSchema>;

// export const SharedLinkResponseSchema = z.union([
//   SharedLinkErrorSchema,
//   SharedLinkSchema,
// ]);

// export type SharedLinkResponse = z.infer<typeof SharedLinkResponseSchema>;

export function songFromAudio(audio: Audio): Song {
  return {
    workspaceId: WorkspaceIdSchema.parse(''), // backend does not return workspace id
    docType: 'song',
    audioId: audio.audio_id,
    coverImagePath: audio.cover_image_path,
    createdAt: new Date(),
    deletedAt: null,
    duration: audio.duration,
    metadata: {
      bitRate: '',
      channelLayout: null,
      channels: '',
      codecLongName: '',
      codecName: '',
      duration: audio.duration,
      formatLongName: '',
      formatName: '',
      metadataComments: null,
      metadataContains_music: null,
      metadataCopyrightDate: null,
      metadataDescription: null,
      metadataDistributor: null,
      metadataExplicit: null,
      metadataGrouping: null,
      metadataIsrc: null,
      metadataIswc: null,
      metadataKey: null,
      metadataLanguage: null,
      metadataLicense: null,
      metadataMasterOwner: null,
      metadataOrder: null,
      metadataPline: null,
      metadataPublisher: null,
      metadataRecordLabel: null,
      metadataReleaseDate: null,
      metadataReleaseTitle: null,
      metadataUpcBarcode: null,
      sampleFmt: '',
      sampleRate: '',
      size: '',
    },
    metadataAlbum: null,
    metadataAlbumArtist: null,
    metadataArtist: audio.metadata_artist,
    metadataBpm: null,
    metadataCodecProfile: '',
    metadataComposer: '',
    metadataCopyright: null,
    metadataFormat: '',
    metadataGenre: '',
    metadataTrackNumber: null,
    metadataYear: 0,
    metadataRating: null,
    metadataTrackCount: null,
    origPath: '',
    originalFilename: null,
    path: null,
    size: 0,
    title: audio.title,
    transcoding: false,
    uploadedAt: null,
    uploading: false,
    uploader: {
      artistName: '',
      avatarUrl: null,
      createdAt: new Date(),
      email: '',
      location: null,
      name: '',
      occupation: null,
      userId: '1' as UserId,
    },
    authenticatedOrigPath: '',
    coverImageOrigPath: '',
    isFavorite: false,
    keyPath: '',
    m3u8Path: '',
    metadataBitrate: 0,
    metadataChannels: 0,
    metadataSampleRate: 0,
    metadataTitle: null,
    resourceId: ResourceIdSchema.parse(''),
    versionId: 0,
    webPlaybackPath: '',
    commentCount: 0,
    encrypted: null,
    favoritedAt: null,
    playCount: 0,
    projectId: 0,
    permissionList: [],
  };
}
